<template>
<div>
    <div ref="carousel" class="carousel">
        <SurveyScramble />
        <NaughtyPack />
        <ABTest test="megapicker-feature" :pct="0.10">
            <template v-slot:control>
                <Megapicker />
            </template>
            <template v-slot:alt>
                <Megapicker bg="alt" />
            </template>
        </ABTest>
        <TMP2 />
    </div>
    <div class="controls">
        <div
            class="arrow-button"
            role="button"
            tabIndex="0"
            :class="{'active': currentSlide > 0}"
            v-on:click="prevSlide"
            v-on:keypress.left="prevSlide"
        >
            <img
                src="/images/icons/carousel-arrow.svg"
                class="left-arrow"
                alt="left"
            >
        </div>
        <div
            v-for="(slide, n) in slides"
            :key="n"
            role="button"
            tabIndex="0"
            class="slide-dot"
            :class="{'active': currentSlide === n}"
            v-on:click="jumpToSlide(n)"
            v-on:keypress.enter="jumpToSlide(n)"
        />
        <div
            class="arrow-button"
            role="button"
            tabIndex="0"
            :class="{'active': currentSlide < numSlides-1}"
            v-on:click="nextSlide"
            v-on:keypress.right="nextSlide"
        >
            <img
                src="/images/icons/carousel-arrow.svg"
                class="right-arrow"
                alt="right"
            >
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Megapicker from '$components/homepage/features/Megapicker.vue'
import TMP2 from '$components/homepage/features/TMP2.vue'
import ABTest from '$components/ABTest.vue'
import NaughtyPack from '$components/homepage/features/NaughtyPack.vue'
import SurveyScramble from '$components/homepage/features/SurveyScramble.vue'

export default defineComponent({
    components: {
        SurveyScramble,
        NaughtyPack,
        Megapicker,
        TMP2,
        ABTest
    },
    data() {
        return {
            slides: <Array<{el:Element, left:number}>> new Array<{el:Element, left:number}>(),
            currentSlide: 0,
            slideIntervalId: -1
        }
    },
    computed: {
        numSlides() {
            return this.slides?.length || 0
        }
    },
    mounted() {
        this.setupSlides()

        // auto advance
        this.slideIntervalId = window.setInterval(() => {
            let slide = this.currentSlide + 1
            if (slide >= this.numSlides) {
                slide = 0
            }
            this.setSlide(slide)
        }, 8000)

        window.addEventListener('resize', this.onResize)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
        this.stopAutoAdvancing()
    },
    methods: {
        setupSlides() {
            const carousel = (this.$refs.carousel as HTMLElement)
            const slideElements = carousel.children

            this.slides = new Array<{el:Element, left:number}>()
            let total = 0
            for (let i = 0; i < slideElements.length; i++) {
                const el = slideElements[i]
                const rect = el.getBoundingClientRect()
                this.slides.push({
                    el,
                    left: total
                })
                total += rect.width + 20
            }
            this.setSlide(0)
        },
        onResize() {
            this.setupSlides()
        },
        nextSlide() {
            this.stopAutoAdvancing()
            this.setSlide(this.currentSlide + 1)
        },
        prevSlide() {
            this.stopAutoAdvancing()
            this.setSlide(this.currentSlide - 1)
        },
        jumpToSlide(n: number) {
            return () => {
                this.stopAutoAdvancing()
                this.setSlide(n)
            }
        },
        setSlide(n: number) {
            if (n < 0 || n >= this.numSlides) {
                return
            }
            this.currentSlide = n
            if (this.slides) {
                this.slides.forEach((slide: {el:Element, left:number}, idx: number) => {
                    if (n === idx) {
                        this.slides[idx].el.classList.add('active-slide')
                        this.slides[idx].el.classList.remove('inactive-slide')
                    } else {
                        this.slides[idx].el.classList.remove('active-slide')
                        this.slides[idx].el.classList.add('inactive-slide')
                    }
                })
                this.centerSlide(n)
            }
        },
        centerSlide(n: number) {
            const carouselElement = (this.$refs.carousel as HTMLElement)
            carouselElement.scrollLeft = this.slides[n].left
        },
        stopAutoAdvancing() {
            if (this.slideIntervalId >= 0) {
                window.clearInterval(this.slideIntervalId)
            }
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../styles/kit.scss";

.carousel {
    margin: 32px 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 20px;
    overflow-x:scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @include mq-medium {
        margin: 24px 0;
    }
    @include mq-small {
        margin: 16px 0;
    }
    @include mq-xsmall {
        margin: 16px 0;
        gap: 16px;
    }
    @include mq-large-and-more {
        margin: 32px auto;
    }

    .carousel-item {
        aspect-ratio: 2.2;
        border-radius: 16px;
        flex-shrink: 0;
        scroll-snap-align: center;
        overflow: hidden;
        height: 100%;
        width: 88vw;
        max-width: 1280px;

        &:first-child {
            margin-left: 6vw;
        }
        &:last-child {
            margin-right: 6vw;
        }

        @include mq-small {
            aspect-ratio: 2.0;
            min-height: 320px;
        }
        @include mq-xsmall {
            aspect-ratio: 0.75;
            min-height: 560px;
        }

        .bg-video {
            width: 100%;
        }

        @include mq-large-and-more {
            transition: opacity 750ms, scale 750ms;
            &:first-child {
                margin-left: calc(100%/4);
            }
            &:last-child {
                margin-right: calc(100%/4);
            }
        }
    }

    .inactive-slide {
        @include mq-large-and-more {
            opacity: 0.3;
            scale: 90%;
        }
    }
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    @include mq-xsmall {
        margin-bottom: 16px;
    }

    .left-arrow {
        transform: scaleX(-1);
    }

    .arrow-button {
        margin: 0 20px;
        opacity: 0.4;
        &.active {
            opacity: 1;
            cursor: pointer;
            transition: all 0.1s ease-out;
            &:hover {
                scale: 1.15 1.15;
            }
        }
    }

    .slide-dot {
        height: 15px;
        width: 15px;
        background-color: #71768E;
        border-radius: 50%;
        display: inline-block;
        transition: all 0.1s ease-out;
        cursor: pointer;
        &:hover {
            scale: 1.15 1.15;
        }

        &.active {
            background-color: #B2BBFF;
            filter: drop-shadow(0px 0px 7.452px #B2BBFF);
            &:hover {
                scale: 1 1;
                cursor: unset;
            }
        }
    }
}
</style>
