<template>
<div class="carousel-item">
    <div
        class="np-feature"
        role="button" tabindex="-1"
        v-on:click.self="navigateToProductPage"
        v-on:keydown.enter="navigateToProductPage"
    >
        <div class="ratings-icon">
            <RatingsIcon />
        </div>
        <div class="overlay">
            <NuxtLink :to="localeRoute({name: 'games-packs-packslug', params: {packslug: 'the-jackbox-naughty-pack'}})">
                <img
                    tabindex="0"
                    src="/images/homepage/features/naughty-pack/np-wordmark.png" alt="The Jackbox Naughty Pack"
                    class="wordmark" role="button"
                >
            </NuxtLink>
            <div class="notes">
                <div class="rating-info">
                    <img v-if="rating === 'ESRB'" class="logo" src="/images/ratings/ESRB-little.png" alt="Rated M for mature">
                    <p class="rating">{{ `RATED ${ageRating}` }}</p>
                </div>
                <p v-if="!pack?.displayWishlistUI" class="release-date"><i>{{ $t('PACK_DETAIL.AVAILABLE_NOW') }}!</i></p>
                <p v-else class="release-date"><i>{{ getReleaseDate() }}</i></p>
            </div>
            <AddToCartButton v-if="!pack?.displayWishlistUI" :product="product" :pack="pack" />
            <a v-else href="https://store.steampowered.com/app/2652000/The_Jackbox_Naughty_Pack/" target="_blank">
                <button class="primary-gradient wide icon-button">
                    <p>{{ $t('HOME.WISHLIST') }}</p>
                    <div class="icons">
                        <img src="/images/icons/windows.svg" alt="Windows" class="windows">
                        <img src="/images/icons/apple.svg" alt="Mac" class="apple">
                        <img src="/images/icons/linux.svg" alt="Linux" class="linux">
                    </div>
                </button>
            </a>
            <NuxtLink :to="localeRoute({name: 'games-packs-packslug', params: {packslug: 'the-jackbox-naughty-pack'}})">
                <button class="outline wide icon-button">
                    <p>{{ $t('GAME_DETAIL.LEARN_MORE') }}</p>
                    <div class="icons">
                        <img src="/images/icons/arrow-right.svg" alt="->" class="arrow-right">
                    </div>
                </button>
            </NuxtLink>
            <StorefrontIcons
                :pack="pack" :platform-options="platformOptions" :fell-back-to-en="fellBackToEn"
                :is-localized="true"
            />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import RatingsIcon from '$components/ratings/RatingsIcon.vue'
import ratingsRegionMapJson from '$components/ratings/ratingsRegionMap.json'
import AddToCartButton from '$components/shopping/AddToCartButton.vue'
import StorefrontIcons from '$components/shopping/StorefrontIcons.vue'
import { Geolocate } from '$services/geolocate'
import { getStorefrontsFromProductData } from '$services/storefronts/storefronts'
import type { JBGWeb } from '$types/JBGWeb'

export default defineComponent({
    components: {
        RatingsIcon,
        AddToCartButton,
        StorefrontIcons
    },
    data() {
        return {
            rating: 'ESRB', // default to ESRB
            pack: <JBGWeb.PackDetail | null>null,
            product: <JBGWeb.ProductDetail | null>null,
            fellBackToEn: false
        }
    },
    computed: {
        ageRating() {
            switch (this.rating) {
            case 'ACB':
                return '15+'
            case 'RARS':
                return '18+'
            case 'USK':
            case 'PEGI':
            case 'ClassInd':
                return '16+'
            default: return 'M 17+'
            }
        },

        platformOptions() {
            if (!this.product) return
            return getStorefrontsFromProductData(this.product)
        }
    },
    mounted() {
        this.getBrowserLocale()
        void this.fetch()
    },
    methods: {
        async fetch() {
            try {
                const pack = await this.$cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-naughty-pack', {
                    locale: this.$i18n.locale
                })

                this.pack = pack
            } catch (error) {
                if ((error as Error).message === 'No data') {
                    try {
                        // force a fetch in en
                        this.fellBackToEn = true
                        const pack = await this.$cms.fetchOne<JBGWeb.PackDetail>('pack-details', 'the-jackbox-naughty-pack', {
                            locale: 'en'
                        })

                        this.pack = pack
                    } catch (error) {
                        console.error('Failed to fetch Naughty Pack fallback content fallback data:', error.message)
                        // void this.$router.push({ name: 'notFound' })
                    }
                } else {
                    console.error('Failed to fetch Naughty Pack data:', error.message)
                    // void this.$router.push({ name: 'notFound' })
                }
            }

            try {
                // get shopify product data
                const filters: Record<string, string> = { name: 'The Jackbox Naughty Pack' }
                const product = await this.$cms.fetchOne<JBGWeb.ProductDetail>('products', '', {
                    locale: this.$i18n.locale,
                    fullResponse: true,
                    filters
                })

                if (!product[0].id) return console.warn('no product id')
                const productDetails = await this.$cms.fetchOne<JBGWeb.ProductDetail>(`products/${product[0].id}`, '' as string, {
                    locale: this.$i18n.locale,
                    fullResponse: true
                })

                this.product = productDetails
            } catch (error) {
                console.error('Failed to fetch Naughty Pack product data:', error.message)
            }
        },

        getBrowserLocale() {
            const countryCode = Geolocate.getCountryCode()
            Object.entries(ratingsRegionMapJson).forEach((rating) => {
                if (rating[1].includes(countryCode)) {
                    this.rating = rating[0]
                }
            })
        },

        getReleaseDate() {
            // funky UTC stuff to get the right date and also give it a localized format
            const date = new Date('2024-09-12')
            const timeDiff = date.getTimezoneOffset() * 60000
            const adjustedDate = new Date(date.valueOf() + timeDiff)
            return adjustedDate.toLocaleDateString(this.$i18n.locale, { month: 'long', day: 'numeric', year: 'numeric' })
        },
        navigateToProductPage() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({ name: 'games-packs-packslug', params: { packslug: 'the-jackbox-naughty-pack' } }))
        }
    }
})
</script>

<style lang="scss" scoped>
@import "../../../styles/kit.scss";

.np-feature {
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('/images/homepage/features/naughty-pack/np-slide-wide.jpg');
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.24) 26.97%, rgba(0, 0, 0, 0.07) 42.52%, rgba(0, 0, 0, 0.00) 100%),
        image-set(url(/images/homepage/features/naughty-pack/np-slide-wide.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;

    @include mq-xsmall {
        background-image: url('/images/homepage/features/naughty-pack/np-slide-mobile.jpg');
        background:
            linear-gradient(180deg, rgba(0, 0, 0, 0.00)48.54%, rgba(0, 0, 0, 0.5) 60.18%),
            image-set(url(/images/homepage/features/naughty-pack/np-slide-mobile.jpg)),;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        flex-direction: column;
        padding-bottom: 16px;
    }

    .ratings-icon {
        width: 87.108px;
        height: auto;
        top: 34px;
        right: 34px;

        @include mq-small {
            top: 16px;
            right: 16px;
        }

        @include mq-xsmall {
            width: 55.792px;
            height: auto;
        }
    }

    .overlay {
        margin: auto 80px;
        max-width: 320px;
        display: flex;
        flex-direction: column;

        @include mq-medium {
            max-width: 280px;
        }

        @include mq-small {
            margin: auto 40px;
            max-width: 320px;
            min-width: 220px;
            width: 35vw;
        }

        @include mq-xsmall {
            margin: 0;
            padding: 0 16px;
            flex-direction: column;
            width: 100%;
            align-self: center;
        }

        .notes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .rating-info {
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    height: 14px;
                    width: 14px;
                }

                p {
                    text-transform: uppercase;
                    margin: 0;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            .release-date {
                display: flex;
                align-items: center;
                margin: 0;
                font-style: italic;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                text-transform: uppercase;
                text-align: right;
            }
        }

        button {
            height: 48px;
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            text-align: left;

            &.primary-gradient {
                font-weight: 800;
                text-transform: uppercase;
                margin: 10px 0px;
            }

            &.outline {
                &:hover {
                    border: 2.5px solid var(--primary-200);
                    color: var(--primary-200);
                    .arrow-right {
                        filter: brightness(0) saturate(100%) invert(82%) sepia(30%) saturate(3659%)
                        hue-rotate(191deg) brightness(103%) contrast(101%);
                    }
                }
            }

            @include mq-small-and-less {
                font-size: 14px;
                line-height: 14px;
                width: 280px
            }
        }

        .icon-button {
            p {
                margin: 0;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;

            .icons {
                display: flex;
                gap: 8px;
                align-items: center;

                img {
                    &.arrow-right {
                        height: 18px;
                        width: 15.429px;
                    }

                    &.windows {
                        height: 16px;
                        width: 17px;
                    }

                    &.apple {
                        height: 16px;
                        width: 14px;
                    }

                    &.linux {
                        height: 18px;
                        width: 15px;
                    }

                    @include mq-small-and-less {
                        &.arrow-right {
                            height: 16px;
                            width: 13.715px;
                        }
                    }
                }
            }
        }

        .wordmark {
            width: 100%;
            animation: flicker 10s -2s infinite;
            transition: transform .2s;
            cursor: pointer;
            &:hover {
                transform: scale(1.10)
            }
        }
    }
}
</style>
